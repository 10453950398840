/** @jsx jsx */
import { jsx } from '@emotion/core'

import Layout from '../components/Layout'

import { LoginWizard } from '../components/pages/Login'

const Login = () => (
  <Layout>
    <LoginWizard />
  </Layout>
)

export default Login
