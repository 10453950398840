/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { toast } from 'react-toastify'
import { useIntl } from 'gatsby-plugin-intl'
import {
  createAccountRequest,
  unsetLoading,
} from '../../../../../store/modules/auth/actions'

import FlexContainer from '../../../../FlexContainer'
import Spacing from '../../../../Spacing'
import Button from '../../../../Button'
import Text from '../../../../Text'
import Title from '../../../../Title'

import Loading from '../../../../../images/icons/loadingbutton.svg'
import { LANGUAGE } from '../../../../../utils/enum/language'
import { getSiteCurrentLanguage } from '../../../../../utils/getLanguage'

const EmailConfirmation = ({ emailMask, loginPhone }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [showCPFField, setShowCPFField] = useState(false)

  useEffect(() => {
    const isLanguagePortuguese =
      getSiteCurrentLanguage(intl) === LANGUAGE.portuguese

    setShowCPFField(isLanguagePortuguese)
  }, [])

  const loading = useSelector(state => state.auth.loading)

  const [username, setName] = useState('')
  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      dispatch(createAccountRequest({ username, email, cpf }))
    } catch (error) {
      toast.error(
        intl.formatMessage({ id: 'toast_messages.error.account_create' })
      )
      dispatch(unsetLoading())
    }
  }

  const data = useStaticQuery(graphql`
    query {
      mobileImageEnglish: file(relativePath: { eq: "img-cadastro-en.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mobileImagePortuguese: file(relativePath: { eq: "img-cadastro-pt.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      desktopImageEnglish: file(relativePath: { eq: "img-cadastro-en.png" }) {
        childImageSharp {
          fixed(width: 690, height: 407) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      desktopImagePortuguese: file(
        relativePath: { eq: "img-cadastro-pt.png" }
      ) {
        childImageSharp {
          fixed(width: 690, height: 407) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <FlexContainer
      css={theme => css`
        ${theme.queries.small} {
          flex-direction: column;
        }

        ${theme.queries.medium} {
          justify-content: space-between;
        }
      `}
    >
      <div
        css={theme => css`
          ${theme.queries.medium} {
            margin-right: -165px;
          }
        `}
      >
        <Title size="48" mobileSize="28" mobileAlign="center" mobileHeight="35">
          {intl.formatMessage({ id: 'pages.login.second_step.title' })}
        </Title>

        {getSiteCurrentLanguage(intl) === LANGUAGE.english && (
          <Img
            fluid={data.mobileImageEnglish.childImageSharp.fluid}
            css={theme => css`
              width: 100%;
              ,height: auto;
              height ${theme.queries.small} {
                margin: 24px 0 28px 15px;
              }

              ${theme.queries.medium} {
                display: none;
              }
            `}
          />
        )}

        {getSiteCurrentLanguage(intl) === LANGUAGE.portuguese && (
          <Img
            fluid={data.mobileImagePortuguese.childImageSharp.fluid}
            css={theme => css`
              width: 100%;
              ,height: auto;
              height ${theme.queries.small} {
                margin: 24px 0 28px 15px;
              }

              ${theme.queries.medium} {
                display: none;
              }
            `}
          />
        )}

        <Spacing top="84">
          <form onSubmit={handleSubmit}>
            <Text mobileSize="16" mobileAlign="center" mobileHeight="28">
              {intl.formatMessage({ id: 'pages.login.second_step.subtitle' })}
            </Text>
            <div
              css={theme => css`
                display: flex;
                margin: 18px 0 22px;
                flex-direction: column;

                input {
                  border: 1px solid #c1c1c1;
                  border-radius: 5px;
                  color: ${theme.colors.jet};
                  font-size: 14px;
                  height: 59px;
                  flex: 1;
                  padding: 20px;

                  & + input {
                    margin-top: 14px;
                  }
                }

                ${theme.queries.medium} {
                  width: 400px;

                  input,
                  select {
                    font-size: 20px;
                  }
                }
              `}
            >
              <input
                type="text"
                placeholder={intl.formatMessage({
                  id: 'pages.login.second_step.input_1',
                })}
                onBlur={e => setName(e.target.value)}
              />
              <input
                type="email"
                placeholder={intl.formatMessage({
                  id: 'pages.login.second_step.input_2',
                })}
                onBlur={e => setEmail(e.target.value)}
              />
              {showCPFField && (
                <InputMask
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'pages.login.second_step.input_3',
                  })}
                  mask="999.999.999-99"
                  onBlur={e => setCpf(e.target.value)}
                />
              )}
            </div>

            <Button
              stretch
              uppercase
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              css={theme => css`
                margin-top: 20px;
                ${theme.queries.medium} {
                  width: 400px;
                }
              `}
            >
              {loading ? (
                <Loading />
              ) : (
                intl.formatMessage({ id: 'pages.login.second_step.button' })
              )}
            </Button>
          </form>
        </Spacing>
      </div>
      {getSiteCurrentLanguage(intl) === LANGUAGE.english && (
        <Img
          fixed={data.desktopImageEnglish.childImageSharp.fixed}
          css={theme => css`
            width: 100%;
            height: auto;

            ${theme.queries.small} {
              display: none !important;
            }
          `}
        />
      )}

      {getSiteCurrentLanguage(intl) === LANGUAGE.portuguese && (
        <Img
          fixed={data.desktopImagePortuguese.childImageSharp.fixed}
          css={theme => css`
            width: 100%;
            height: auto;

            ${theme.queries.small} {
              display: none !important;
            }
          `}
        />
      )}
    </FlexContainer>
  )
}

EmailConfirmation.propTypes = {
  emailMask: PropTypes.string.isRequired,
  loginPhone: PropTypes.string.isRequired,
}

export default EmailConfirmation
