/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'

import { loginRequest } from '../../../../../store/modules/auth/actions'

import FlexContainer from '../../../../FlexContainer'
import Spacing from '../../../../Spacing'
import Button from '../../../../Button'
import Text from '../../../../Text'
import Title from '../../../../Title'

import Loading from '../../../../../images/icons/loadingbutton.svg'

const EmailConfirmation = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const emailMask = useSelector(state => state.auth.emailMask)
  const loading = useSelector(state => state.auth.loading)

  const [email, setEmail] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    dispatch(loginRequest(email))
  }

  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "img-confimacao-email.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      desktopImage: file(relativePath: { eq: "img-confimacao-email.png" }) {
        childImageSharp {
          fixed(width: 630, height: 412) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <FlexContainer
      css={theme => css`
        ${theme.queries.small} {
          flex-direction: column;
        }

        ${theme.queries.medium} {
          justify-content: space-between;
        }
      `}
    >
      <div
        css={theme => css`
          ${theme.queries.medium} {
            margin-right: -165px;
          }
        `}
      >
        <Title size="48" mobileSize="28" mobileAlign="center" mobileHeight="35">
          {intl.formatMessage({ id: 'pages.login.third_step.title' })}
        </Title>
        <Img
          fluid={data.mobileImage.childImageSharp.fluid}
          css={theme => css`
            width: 100%;
            height: auto;

            ${theme.queries.small} {
              margin: 24px 0 28px 15px;
            }

            ${theme.queries.medium} {
              display: none;
            }
          `}
        />

        <Spacing top="84">
          <form onSubmit={handleSubmit}>
            <Text mobileSize="16" mobileAlign="center" mobileHeight="28">
              {intl.formatMessage({ id: 'pages.login.third_step.subtitle' })}
            </Text>
            <div
              css={theme => css`
                display: flex;
                margin: 18px 0 22px;
                flex-direction: column;

                input {
                  border: 1px solid #c1c1c1;
                  border-radius: 5px;
                  color: ${theme.colors.jet};
                  font-size: 14px;
                  height: 59px;
                  flex: 1;
                  padding: 20px;

                  & + input {
                    margin-top: 14px;
                  }
                }

                ${theme.queries.medium} {
                  width: 400px;

                  input,
                  select {
                    font-size: 20px;
                  }
                }
              `}
            >
              <input type="text" value={emailMask} readOnly />
              <input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <Button
              stretch
              uppercase
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              css={theme => css`
                margin-top: 20px;
                ${theme.queries.medium} {
                  width: 400px;
                }
              `}
            >
              {loading ? (
                <Loading />
              ) : (
                intl.formatMessage({ id: 'pages.login.third_step.button' })
              )}
            </Button>
          </form>
        </Spacing>
      </div>
      <Img
        fixed={data.desktopImage.childImageSharp.fixed}
        css={theme => css`
          width: 100%;
          height: auto;

          ${theme.queries.small} {
            display: none !important;
          }
        `}
      />
    </FlexContainer>
  )
}

export default EmailConfirmation
