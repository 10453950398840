/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import InputMask from 'react-input-mask'
import { useFirebase } from 'gatsby-plugin-firebase'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { toast } from 'react-toastify'
import { useIntl } from 'gatsby-plugin-intl'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import {
  setEmailMask,
  setFacebookCode,
  setPhoneNumber,
  setPhoneRecognized,
  setPhoneNotRecognized,
} from '../../../../../store/modules/auth/actions'
import { verifyPhoneExists } from '../../../../../services/authentication'

import FlexContainer from '../../../../FlexContainer'
import Spacing from '../../../../Spacing'
import Button from '../../../../Button'
import Text from '../../../../Text'
import Title from '../../../../Title'

import Loading from '../../../../../images/icons/loadingbutton.svg'
import { removeSpecialChars } from '../../../../../utils/formatString'

const PhoneAuthentication = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [denarioFirebase, setFirebase] = useState(null)
  const [phoneNumber, setInputPhoneNumber] = useState('')
  const [isLoading, setLoading] = useState(true)
  const [confirmationResult, setConfirmationResult] = useState(null)

  const [userCodeInput, setUserCodeInput] = useState('')
  const [verificationId, setVerificationId] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [recaptchaRef, setRecaptchaRef] = useState(null)
  const [recaptcha, setRecaptcha] = useState(null)

  useFirebase(firebase => {
    setFirebase(firebase)

    firebase.auth().languageCode = 'pt'
    const recaptcha = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'normal',
        callback(response) {
          setRecaptchaToken(response)
        },
      }
    )

    setRecaptchaRef(recaptcha)

    document.getElementById('recaptcha-container').innerHTML = ''
    setTimeout(() => {
      recaptcha.render().then(() => {
        setRecaptcha(recaptcha)
      })
    }, 50)

    setLoading(false)
  }, [])

  const handleSendSMS = event => {
    event.preventDefault()

    setLoading(true)

    if (removeSpecialChars(phoneNumber).length < 11) {
      setLoading(false)
      toast.warn(
        intl.formatMessage({ id: 'toast_messages.warning.input_format' })
      )
      return
    }

    if (!recaptchaToken) {
      setLoading(false)
      toast.warn(intl.formatMessage({ id: 'toast_messages.warning.recaptcha' }))
      return
    }

    denarioFirebase
      .auth()
      .signInWithPhoneNumber(`+${removeSpecialChars(phoneNumber)}`, recaptcha)
      .then(confirmationResult => {
        setVerificationId(confirmationResult.verificationId)
        dispatch(setPhoneNumber(`+${removeSpecialChars(phoneNumber)}`))
        setConfirmationResult(confirmationResult)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if (error.code === 'auth/too-many-requests') {
          toast.warn(
            intl.formatMessage({
              id: 'toast_messages.warning.too_many_requests',
            })
          )
        } else {
          toast.error(error.message)
        }
      })
  }

  const handleResendSMS = () => {
    setVerificationId(null)
    setRecaptchaToken(null)

    setTimeout(() => {
      denarioFirebase.auth().languageCode = 'pt'
      const recaptcha = new denarioFirebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'normal',
          callback(response) {
            setRecaptchaToken(response)
          },
        }
      )

      setRecaptchaRef(recaptcha)

      document.getElementById('recaptcha-container').innerHTML = ''
      setTimeout(() => {
        recaptcha.render().then(() => {
          setRecaptcha(recaptcha)
        })
      }, 10)
    }, 10)
  }

  const handleConfirmationCode = event => {
    event.preventDefault()

    setLoading(true)

    const verifyNumber = async () => {
      try {
        const response = await verifyPhoneExists(
          `${removeSpecialChars(phoneNumber)}`
        )

        dispatch(setPhoneNumber(response.data.phoneNumber))
        dispatch(setEmailMask(response.data.emailMask))
        dispatch(setPhoneRecognized())
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            dispatch(setPhoneNotRecognized())
          }

          if (error.response.status !== 200 && error.response.status !== 404) {
            toast.warn(error.message)
          }
        }
      }
    }

    if (removeSpecialChars(userCodeInput).length === 6) {
      confirmationResult
        .confirm(userCodeInput)
        .then(result => {
          dispatch(
            setFacebookCode(denarioFirebase.auth().currentUser.getIdToken())
          )

          verifyNumber()
        })
        .catch(error => {
          setLoading(false)
          toast.error(error.message)
        })
    } else {
      setLoading(false)
      toast.warn(
        intl.formatMessage({ id: 'toast_messages.warning.fill_code_field' })
      )
    }
  }

  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "img-celular-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 377) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      desktopImage: file(relativePath: { eq: "img-celular.png" }) {
        childImageSharp {
          fixed(width: 630, height: 430) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  const globalPhoneMask = /^[0-9]/

  return (
    <FlexContainer>
      <div
        css={theme => css`
          ${theme.queries.medium} {
            margin-right: -165px;
          }
        `}
      >
        <Title size="48" mobileSize="28" mobileAlign="center" mobileHeight="35">
          {intl.formatMessage({ id: 'pages.login.first_step.title' })}
        </Title>
        <Text
          size="20"
          mobileSize="14"
          mobileAlign="center"
          mobileHeight="22"
          marginBottom="20"
        >
          {intl.formatMessage({ id: 'pages.login.first_step.subtitle' })}
        </Text>

        <Text
          color="arsenic"
          size="16"
          mobileSize="14"
          mobileAlign="center"
          mobileHeight="22"
        >
          {intl.formatMessage({
            id: 'pages.login.first_step.institution_message',
          })}{' '}
          <a
            href="http://sistema.denario.app/admin/login"
            target="blank"
            css={css`
              text-decoration: underline;
            `}
          >
            {intl.formatMessage({ id: 'pages.login.first_step.click_here' })}
          </a>
        </Text>

        <Img
          fluid={data.mobileImage.childImageSharp.fluid}
          css={theme => css`
            width: 100%;
            height: auto;

            ${theme.queries.small} {
              margin: 24px 0 28px 15px;
            }

            ${theme.queries.medium} {
              display: none;
            }
          `}
        />

        {!verificationId && (
          <Spacing top="50">
            <form onSubmit={handleSendSMS}>
              <Text mobileSize="16" mobileAlign="center" mobileHeight="28">
                {intl.formatMessage({
                  id: 'pages.login.first_step.insert_number',
                })}
              </Text>
              <div
                css={theme => css`
                  display: flex;
                  margin: 18px 0 22px;

                  input,
                  select {
                    border: 1px solid #c1c1c1;
                    border-radius: 5px;
                    color: ${theme.colors.jet};
                    font-size: 14px;
                    height: 59px;
                    flex: 1;
                    padding: 20px;
                  }

                  select {
                    margin-right: 11px;
                    max-width: 116px;
                    padding: 0px 20px;
                    -webkit-appearance: none;
                  }

                  ${theme.queries.medium} {
                    width: 400px;

                    input,
                    select {
                      font-size: 20px;
                    }
                  }
                `}
              >
                <PhoneInput
                  international
                  defaultCountry="BR"
                  value={phoneNumber}
                  onChange={val => {
                    setInputPhoneNumber(val)
                  }}
                />
              </div>

              <div id="recaptcha-container" />

              <Button
                stretch
                uppercase
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                css={theme => css`
                  margin-top: 20px;
                  ${theme.queries.medium} {
                    width: 400px;
                  }
                `}
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  intl.formatMessage({ id: 'pages.login.first_step.send' })
                )}
              </Button>
            </form>
          </Spacing>
        )}
        {verificationId && (
          <Spacing top="84">
            <form onSubmit={handleConfirmationCode}>
              <Text
                size="16"
                lineHeight="28"
                mobileSize="16"
                mobileAlign="center"
                mobileHeight="28"
                css={theme => css`
                  ${theme.queries.medium} {
                    width: 380px;
                  }
                `}
              >
                {intl.formatMessage({
                  id: 'pages.login.first_step.insert_code',
                })}{' '}
                <strong>{phoneNumber}</strong>
              </Text>
              <div
                css={theme => css`
                  display: flex;
                  margin: 18px 0 22px;

                  input {
                    border: 1px solid #c1c1c1;
                    border-radius: 5px;
                    color: ${theme.colors.jet};
                    font-size: 14px;
                    height: 59px;
                    flex: 1;
                    padding: 20px;
                    text-align: center;
                  }

                  ${theme.queries.medium} {
                    width: 400px;
                  }
                `}
              >
                <InputMask
                  type="text"
                  mask="999999"
                  placeholder="******"
                  value={userCodeInput}
                  onChange={e => setUserCodeInput(e.target.value)}
                />
              </div>

              <FlexContainer
                css={css`
                  flex-direction: column;
                  max-width: 400px;
                `}
              >
                <Button
                  stretch
                  uppercase
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                  css={theme => css`
                    margin-top: 20px;
                    ${theme.queries.medium} {
                      width: 400px;
                    }
                  `}
                >
                  {isLoading ? (
                    <Loading />
                  ) : (
                    intl.formatMessage({
                      id: 'pages.login.first_step.confirm_code',
                    })
                  )}
                </Button>

                <button
                  type="button"
                  onClick={e => handleResendSMS(e)}
                  css={css`
                    background-color: transparent;
                    border: 0;
                    cursor: pointer;
                    margin-top: 10px;
                    text-decoration: underline;
                    outline: 0;
                  `}
                >
                  {intl.formatMessage({ id: 'pages.login.first_step.resend' })}
                </button>
              </FlexContainer>
            </form>
          </Spacing>
        )}
      </div>
      <Img
        fixed={data.desktopImage.childImageSharp.fixed}
        css={theme => css`
          width: 100%;
          height: auto;

          ${theme.queries.small} {
            display: none !important;
          }
        `}
      />
    </FlexContainer>
  )
}

export default PhoneAuthentication
