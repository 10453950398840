/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useSelector } from 'react-redux'

import Spacing from '../../Spacing'
import Wrapper from '../../Wrapper'
import PhoneAuthentication from './Steps/PhoneAuthentication'
import EmailConfirmation from './Steps/EmailConfirmation'
import Registration from './Steps/Registration'

import { STEP } from '../../../utils/enum/login'

const Login = () => {
  const step = useSelector(state => state.auth.step)

  return (
    <Spacing top="114" bottom="216" bottomMobile="37">
      <Wrapper>
        {step === STEP.phoneAuthentication && <PhoneAuthentication />}
        {step === STEP.emailConfirmation && <EmailConfirmation />}
        {step === STEP.registration && <Registration />}
      </Wrapper>
    </Spacing>
  )
}

export { Login as LoginWizard }
